<template>
  <div class="content-station-management-switches">
    <StationGameSwitch
      v-if="gameType === 'cds'"
      class="stationswitch"
      :title="title"
      :stationGame="stationGame"
      @uninstall="uninstallStationGame"
      @install="installStationGame"
      @disable="disableStationGame"
    />
    <SteamKeySwitch
      v-else-if="gameType === 'steam' && freeDownload"
      class="stationswitch"
      :title="title"
      :stationGame="stationGame"
      @request-key="requestKeyForStationGame"
      @enable="enableStationGame"
      @disable="disableStationGame"
    />
    <UISwitch
      v-else
      class="stationswitch"
      :title="title"
      :value="stationGame.enabled"
      :instructions="switchLabel"
      @change="onSwitchChange"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import UISwitch from '@/core/shared/components/ui/UISwitch';
import SteamKeySwitch from './SteamKeySwitch';
import StationGameSwitch from './StationGameSwitch';

@Component({
  components: {
    UISwitch,
    SteamKeySwitch,
    StationGameSwitch,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    gameType: {
      type: String,
      required: true,
    },
    stationGame: {
      type: Object,
      required: true,
    },
    labels: {
      type: Boolean,
      default: false,
    },
  },
})
export default class ContentStationManagementSwitches extends Vue {
  get switchLabel () {
    if (this.labels) {
      return (this.gameType !== 'steam') ? this.$t('Manually Added') : this.$t('Buy On Steam');
    }
  }

  get freeDownload () {
    const { ownership } = this.$store.getters['entities/Game'](this.stationGame.game.id);
    return ownership && ownership.freeDownload;
  }

  installStationGame (stationGame) {
    this.$emit('installStationGame', stationGame);
  }

  uninstallStationGame (stationGame) {
    this.$emit('uninstallStationGame', stationGame);
  }

  requestKeyForStationGame (stationGame) {
    this.$emit('requestKeyForStationGame', stationGame);
  }

  enableStationGame (stationGame, gameType) {
    this.$emit('enableStationGame', stationGame, this.gameType);
  }

  disableStationGame (stationGame) {
    this.$emit('disableStationGame', stationGame, this.gameType);
  }

  onSwitchChange ({ target }) {
    const { checked } = target;
    if (checked) {
      this.enableStationGame(this.stationGame);
    } else {
      this.disableStationGame(this.stationGame);
    }
  }
}
</script>

<style lang="postcss" scoped>
@import "core/shared/styles";

.content-station-management-switches {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--spacingSm);
}
</style>
