
<template>
  <UISwitch
    v-bind="propsToForward"
    v-on="$listeners"
    ref="switch"
    class="station-game-switch"
    @click="onClick"
  >
    <template #additional v-if="shouldShowUninstallButton">
      <UIButton tiny class="uninstallbtn" @click="uninstall">{{ $t('Uninstall') }}</UIButton>
    </template>
    <template #additional v-else-if="isTransitioningToCDS">
      <UIButton tiny class="installbtn" @click="install">{{ $t('Update') }}</UIButton>
    </template>
  </UISwitch>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import UISwitch from '@/core/shared/components/ui/UISwitch';
import UIButton from '@/core/shared/components/ui/UIButton';

@Component({
  components: {
    UISwitch,
    UIButton,
  },
  props: {
    title: { required: true },
    stationGame: { required: true },
  },
})
export default class StationGameSwitch extends Vue {
  get stationGameStatus () {
    const { status, targetStatus } = this.stationGame;
    if (status === 'updating') return this.stationGame._status || status;
    const computedStatus = (function () {
      if (status && targetStatus) {
        const match = targetStatus.match(/(.*)_pending$/);
        if (match) {
          const [, targetStatusKind] = match;
          const isSimilar = status.slice(0, targetStatusKind.length) === targetStatusKind;
          return isSimilar ? status : targetStatus;
        }
      }
    })();
    return this.stationGame._status || computedStatus || targetStatus || status;
  }

  get shouldShowUninstallButton () {
    return this.stationGameStatus === 'installed';
  }

  get isTransitioningToCDS () {
    return this.stationGame.enabled && !this.stationGameStatus;
  }

  onClick (e) {
    const val = e.target.checked;
    if (!val) {
      if ('id' in this.stationGame && this.stationGame._originalValue === true) {
        e.preventDefault();
        this.showAskDisableOrUninstallModal();
      } else { // no need for confirm here since it is not saved yet
        this.disable();
      }
    } else {
      this.install();
    }
  }

  showAskDisableOrUninstallModal () {
    this.$store.commit('setConfirm', {
      show: true,
      message: this.$t('If you would like to keep the content installed on your computer but disabled on your station, select “Disable Only”. To uninstall the content, click on “Disable & Uninstall”.'),
      icon: 'x',
      buttons: [
        {
          name: this.$t('Disable Only'),
          type: 'ghost',
          action: () => this.disable(),
        },
        {
          name: this.$t('Disable & Uninstall'),
          action: () => this.uninstall(),
        },
      ],
    });
  }

  disable () {
    this.$emit('disable', this.stationGame);
  }

  uninstall () {
    this.$emit('uninstall', this.stationGame);
  }

  install () {
    this.$emit('install', this.stationGame);
  }

  get propsToForward () {
    let instructions;
    let styleClass;
    switch (this.stationGameStatus) {
    // local client status
    case 'install_pending_save':
      instructions = this.$t('Will install after saving');
      styleClass = 'info';
      break;
    case 'uninstall_pending_save':
      instructions = this.$t('Will uninstall after saving');
      styleClass = 'info';
      break;
    case 'enabled_pending_save':
      instructions = this.$t('Will be enabled after saving');
      styleClass = 'info';
      break;
    case 'disabled_pending_save':
      instructions = this.$t('Will be disabled after saving');
      styleClass = 'info';
      break;
      // backend status
    case 'install_pending':
      instructions = this.$t('Install pending');
      styleClass = 'info';
      break;
    case 'installing':
      instructions = this.$t('Installing - {percentage}%', { percentage: this.stationGame.progress });
      styleClass = 'info';
      break;
    case 'updating':
      instructions = this.$t('Updating - {percentage}%', { percentage: this.stationGame.progress });
      styleClass = 'info';
      break;
    case 'install_error':
      instructions = this.$t('Error during install');
      styleClass = 'error';
      break;
    case 'uninstalling':
      instructions = this.$t('Uninstall in progress');
      styleClass = 'info';
      break;
    case 'uninstall_pending':
      instructions = this.$t('Uninstall pending');
      styleClass = 'info';
      break;
    case 'uninstall_error':
      instructions = this.$t('Error during uninstall');
      styleClass = 'error';
      break;
    default:
      instructions = this.$t('Not installed');
      break;
    }
    if (this.shouldShowUninstallButton || this.isTransitioningToCDS) instructions = undefined;
    const { enabled } = this.stationGame;
    return {
      title: this.title,
      instructions,
      value: enabled,
      class: styleClass && `-${styleClass}`,
    };
  }
}
</script>
<style lang="postcss" scoped>
@import "core/shared/styles";
.station-game-switch {
  &.-info {
    & >>> .instructions {
      color: var(--colorInfoFlat);
    }
  }
  &.-error {
    & >>> .instructions {
      color: var(--colorErrorFlat);
    }
  }
}
</style>
