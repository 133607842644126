
<template>
  <UISwitch
    v-bind="propsToForward"
    v-on="$listeners"
    ref="switch"
    class="steam-key-switch"
    :instructions="instructions"
    @change="onChange"
  >
    <template #additional>
      <UIButton
        v-if="!stationGame.key"
        link
        class="requestkeybtn blue"
        @click="requestKey"
      >{{ $t('Request Steam Key') }}</UIButton>
      <UIButton
        v-else-if="stationGame.key.key === 'Pending'"
        warning
        icon="alert-circle"
        class="requestPendingBtn"
        @click="showKeyRequestPendingMessage"
      >{{ $t('Steam key request pending') }}</UIButton>

      <p v-else class="key blue f-thead">{{ stationGame.key.key }}</p>
    </template>
  </UISwitch>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import UISwitch from '@/core/shared/components/ui/UISwitch';
import UIButton from '@/core/shared/components/ui/UIButton';

@Component({
  components: {
    UISwitch,
    UIButton,
  },
  props: {
    title: { required: true },
    stationGame: { required: true },
    instructions: { required: false },
  },
})
export default class SteamKeySwitch extends Vue {
  get shouldShowUninstallButton () {
    return this.stationGame.status === 'installed';
  }

  onChange (e) {
    const val = e.target.checked;
    if (!val) {
      this.disable();
    } else {
      this.enable();
    }
  }

  disable () {
    this.$emit('disable', this.stationGame);
  }

  showKeyRequestPendingMessage () {
    const message = `There are currently no keys available for this title, an automated email has been sent to the Content Creator. 
    <br> When more keys are uploaded, one will be assigned automatically. 
    <br> Unfortunately, we are unable to provide any estimate on how long this could take`;
    this.$store.commit('setFlash', {
      type: 'info',
      message: this.$t(message),
    });
  }

  requestKey () {
    this.$emit('request-key', this.stationGame);
  }

  enable () {
    this.$emit('enable', this.stationGame);
  }

  get propsToForward () {
    const { enabled } = this.stationGame;
    return {
      title: this.title,
      value: enabled,
    };
  }
}
</script>
<style lang="postcss" scoped>
@import "core/shared/styles";
.steam-key-switch {
  &.-info {
    & >>> .instructions {
      color: var(--colorInfoFlat);
    }
  }
  &.-error {
    & >>> .instructions {
      color: var(--colorErrorFlat);
    }
  }
  & .requestkeybtn {
    text-align: left;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--colorInfoFlat);
  }
  & .requestPendingBtn {
    text-align: left;
    font-weight: 600;
    text-transform: uppercase;
  }
}
</style>
